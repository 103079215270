/* Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
SPDX-License-Identifier: Apache-2.0*/

@font-face {
    font-family: "Buch";
    src: url("./fonts/Sohne-Buch.otf") format("opentype");
}

@font-face {
    font-family: "Kraftig";
    src: url("./fonts/Sohne-Kraftig.otf") format("opentype");
}

@font-face {
    font-family: "Leicht";
    src: url("./fonts/Sohne-Leicht.otf") format("opentype");
}

body {
  margin: 0;
  padding: 0;
  font-family: "Leicht", sans-serif;
}

h1, h2, h3, h4, h5, H6 {
  font-family: "Buch", sans-serif !important;
  color: #2D1D49 !important;
  line-height: initial;
}

p {
    font-family: "Leicht", sans-serif !important;
    line-height: initial;
    color: #2D1D49 !important;
}

li {
  font-family: "Leicht", sans-serif;
  line-height: initial;
  color: #2D1D49 !important;
  margin: 0.25em 0;
}

a.item {
    color: white !important;
}

a {
    font-family: "Leicht", sans-serif;
    color: #967dff !important;
}

#root {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.fixfloat:after {
  content: "";
  display: table;
  clear: both;
}

@keyframes App-logo-spin {
  from { transform: scale(1); }
  to { transform: scale(1.08); }
}
